<template>
  <div>
    <!--  面包屑  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>库存管理</el-breadcrumb-item>
      <el-breadcrumb-item>采购订单</el-breadcrumb-item>
      <el-breadcrumb-item>新增采购订单</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <el-form  :model="addPurchaseForm" :rules="addPurchaseFormRules" ref="addPurchaseFormRef"
                label-width="100px">
        <el-row>
          <el-col>
            <el-form-item label="供应商"  prop="s_id">
              <el-select v-model="addPurchaseForm.s_id" placeholder="请选择" @change="getcheckList" clearable filterable>
                <el-option
                    v-for="item in supplierList"
                    :key="item.s_id"
                    :label="item.s_company_name"
                    :value="item.s_id">
                </el-option>
              </el-select>
              <el-button class="addsupplierselect" @click="AddSupplierSelect" type="primary">添加供应商</el-button>
            </el-form-item>
          </el-col>
          <el-col>
            <el-button @click="add" type="primary" :disabled="adddisabled">添加</el-button>
          </el-col>
        </el-row>
        <el-form-item label-width="0" min-width="760px">
          <el-table :data="addPurchaseForm.goodslist" border stripe>
            <el-table-column type="index"></el-table-column>
            <el-table-column label="商品编码" prop="ga_code" min-width="70px" show-overflow-tooltip></el-table-column>
            <el-table-column label="商品名称" prop="g_name" min-width="70px" show-overflow-tooltip></el-table-column>
            <el-table-column label="商品规格" prop="ga_name" min-width="70px" show-overflow-tooltip></el-table-column>
            <el-table-column label="采购数量" prop="pi_number" min-width="110px">
              <template slot-scope="scope">
                <el-input v-model.number="scope.row.pi_number" placeholder="采购数量(数字)" @blur="getTotal" @keydown="handleInput2"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="采购单价" prop="pi_price" min-width="110px">
              <template slot-scope="scope">
                <el-input v-model="scope.row.pi_price" placeholder="采购单价(数字)" @blur="money(scope.row.pi_price)" @keyup.native="scope.row.pi_price = money(scope.row.pi_price)" @keydown="handleInput2"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="小计" prop="pi_total_price">
              <template slot-scope="scope">
                <!--                  {{scope.row.pi_total_price=scope.row.pi_number*scope.row.pi_price||0}}-->
                {{ scope.row.pi_total_price=NumberMul(scope.row.pi_number,scope.row.pi_price) || 0}}
              </template>
            </el-table-column>
            <el-table-column label="单位" prop="gu_name" min-width="50px" show-overflow-tooltip></el-table-column>

            <el-table-column label="删除" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-button type="danger" @click="delGoodsList(scope.row.ga_id)">删除</el-button>
              </template>
            </el-table-column>

          </el-table>
        </el-form-item>
        <!--        <el-form-item label="采购金额：" prop="p_price">-->
        <!--          <el-input class="p_price" v-model="addPurchaseForm.p_price"></el-input>-->
        <!--        </el-form-item>-->
        <el-form-item label="采购员：" prop="p_purchase_id">
          <el-select v-model="addPurchaseForm.p_purchase_id" placeholder="请选择"
                     clearable class="p_purchase_id">
            <el-option
                v-for="item in userlist"
                :key="item.m_id"
                :label="item.m_name"
                :value="item.m_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="采购日期：" prop="p_date">
          <el-date-picker v-model="addPurchaseForm.p_date" class="p_date"
                          type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="其他金额：" prop="p_other_price">
          <el-input v-model="addPurchaseForm.p_other_price" @keyup.native="addPurchaseForm.p_other_price = money(addPurchaseForm.p_other_price)" class="p_other_price"></el-input>
        </el-form-item>
        <el-form-item label="备注：" prop="p_remark">
          <el-input v-model="addPurchaseForm.p_remark" class="p_remark"></el-input>
        </el-form-item>
        <el-form-item label="采购金额：" prop="">
          <el-input v-model="addPurchaseForm.p_total_price" class="p_other_price" disabled></el-input>
        </el-form-item>
        <!--        提交-->
        <div style="float: right; padding-bottom:20px; ">
          <el-button @click="addgo">取消</el-button>

          <el-button type="primary" @click="addupPurchase" :loading="addPloading">确定</el-button>
        </div>
      </el-form>

    </el-card>

    <el-dialog  title="选择商品" :visible.sync="addGoodsDialogVisible"
                width="70%">
      <el-row :gutter="30">
        <el-col :span="6">
          <el-input placeholder="请输入内容" v-model="querInfo.g_name" clearable @clear="getGoodsList(querInfo)">
            <el-button slot="append" icon="el-icon-search" @click="getGoodsList(querInfo)"></el-button>
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-cascader v-model="selectefKeys" :options="categorieslList" clearable placeholder="请选择商品分类"
                       :props="cascaderPropscate" @change="parentCateChanged"></el-cascader>
        </el-col>
      </el-row>
      <el-table :data="Goodslist" stripe @select-all="changeFun" @select="asd" ref="docTable">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="商品编码" prop="ga_code" width="100px"></el-table-column>
        <el-table-column label="商品名称" prop="g_name"></el-table-column>
        <el-table-column label="商品规格" prop="ga_name">
          <template slot-scope="scope" >
            <div>{{scope.row.ga_name}}</div>
            <!--            <div v-if="scope.row.ga_start_date">{{scope.row.ga_start_date}}{{scope.row.ga_end_date}}</div>-->
          </template>
        </el-table-column>
        <el-table-column label="单位" prop="gu_name" width="120px"></el-table-column>
        <el-table-column label="质量等级" prop="ql_name" width="120px"></el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="querInfo.page"
          :page-sizes="[3,5,10,15]"
          :page-size="querInfo.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addgoods">添加商品</el-button>
        <el-button @click="addGoodsDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addGoodsDialogVisible = false" >确 定</el-button>
      </span>
    </el-dialog>
    <!--  添加对话框  -->
    <el-dialog
        title="添加供应商"
        :visible.sync="addSupplierDialogVisible"
        width="60%"
        @close="addSupplieDialoghandleClose">

      <el-form ref="addsupplierFormRef" :model="addsupplierForm" label-width="100px"
               :rules="addsupplierFormRules" >
        <el-form-item label="供应商名称" prop="s_company_name">
          <el-input v-model="addsupplierForm.s_company_name"></el-input>
        </el-form-item>
        <el-form-item label="选择地址" prop="region">
          <el-cascader
              v-model="addsupplierForm.region" clearable @change="addSupplierChanged"
              :options="listarea" :props="cascaderProps"
          ></el-cascader>
        </el-form-item>

        <el-form-item label="详细地址" prop="s_detail">
          <el-input v-model="addsupplierForm.s_detail"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="s_name">
          <el-input v-model="addsupplierForm.s_name"></el-input>
        </el-form-item>

        <el-form-item label="手机号" prop="s_phone">
          <el-input v-model="addsupplierForm.s_phone"></el-input>
        </el-form-item>

        <el-form-item label="座机号码" prop="s_mobile">
          <el-input v-model="addsupplierForm.s_mobile"></el-input>
        </el-form-item>

        <el-form-item label="职位" prop="s_job">
          <el-input v-model="addsupplierForm.s_job"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="s_email">
          <el-input v-model="addsupplierForm.s_email"></el-input>
        </el-form-item>
        <el-form-item label="开户名称" prop="s_account_name">
          <el-input v-model="addsupplierForm.s_account_name"></el-input>
        </el-form-item>
        <el-form-item label="开户银行" prop="s_deposit_bank">
          <el-input v-model="addsupplierForm.s_deposit_bank"></el-input>
        </el-form-item>
        <el-form-item label="银行账号" prop="s_account_bank">
          <el-input v-model="addsupplierForm.s_account_bank"></el-input>
        </el-form-item>
        <!--        <el-form-item label="发票抬头" prop="s_invoice_title">-->
        <!--          <el-input v-model="addsupplierForm.s_invoice_title"></el-input>-->
        <!--        </el-form-item>-->
        <el-form-item label="纳税人识别号" prop="s_taxpayer">
          <el-input v-model="addsupplierForm.s_taxpayer"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="s_remarks">
          <el-input v-model="addsupplierForm.s_remarks"></el-input>
        </el-form-item>


      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="addSupplierDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addSupplier" :loading="addloading">确 定</el-button>
      </span>

    </el-dialog>
  </div>
</template>
<script>
// import {store} from '@/store/store'
export default {
  data() {
    return {
      addPloading:false,
      addloading:false,
      querInfo: {
        page: 1,
        limit: 10,
        's_id':'',
        g_cid:''
      },
      //控制添加供应商对话框的展示与隐藏
      addSupplierDialogVisible:false,
      //添加供应商的form表单数据
      addsupplierForm:{},
      // 省市区信息
      listarea: [],
      //三级联动的配置
      cascaderProps: {
        expandTrigger: 'hover',
        // checkStrictly:true,
        value: 'la_id',
        label: 'la_name',
        children: 'children'
      },
      //添加form表单的验证
      addsupplierFormRules:{
        s_company_name:[
          {required: true, message: '请输入供应商的名称', trigger: 'blur'}
        ],
        // s_detail:[
        //   {required: true, message: '请输入详细地址', trigger: 'blur'}
        // ],
        // s_name:[
        //   {required: true, message: '请输入联系人', trigger: 'blur'}
        // ],
        // s_phone:[
        //   {required: true, message: '请输入手机号', trigger: 'blur'}
        // ],
        // s_mobile:[
        //   {required: true, message: '请输入座机号码', trigger: 'blur'}
        // ],
        // s_job:[
        //   {required: true, message: '请输入职位', trigger: 'blur'}
        // ],
        // s_email:[
        //   {required: true, message: '请输入邮箱', trigger: 'blur'}
        // ],
        // s_account_name:[
        //   {required: true, message: '请输入开户名称', trigger: 'blur'}
        // ],
        // s_deposit_bank:[
        //   {required: true, message: '请输入开户银行', trigger: 'blur'}
        // ],
        // s_account_bank:[
        //   {required: true, message: '请输入银行账号', trigger: 'blur'}
        // ],
        // s_invoice_title:[
        //   {required: true, message: '请输入发票抬头', trigger: 'blur'}
        // ],
        // s_remarks:[
        //   {required: true, message: '请输入备注', trigger: 'blur'}
        // ],
        region:[
          {required: true, message: '请选择地址', trigger: 'change'}
        ]
      },
      total:0,
      //添加表单的form表单
      addPurchaseForm:{
        goodslist:[

        ],
        p_total_price:0,
        p_date:this.$store.state.daydate,
        p_purchase_id:this.$store.state.m_id
      },
      Goodslist:[],
      //员工列表
      userlist:[],
      //供应商列表
      supplierList:[],
      //选中供应商的数组
      ss_id: '',
      id:{'s_id':''},
      //控制添加商品的显示或隐藏
      addGoodsDialogVisible:false,
      checkBoxData: [],
      //
      adddisabled:true,
      addPurchaseFormRules: {
        s_id: [
          {required: true, message: '请选择供应商', trigger: 'change'},
        ],
        p_purchase_id: [
          {required: true, message: '请选择采购员', trigger: 'change'},
        ],
        p_date: [
          {required: true, message: '请选择采购时间', trigger: 'change'},
        ],
        // p_other_price: [
        //   {required: true, message: '请输入金额', trigger: 'change'},
        // ],
        // p_remark: [
        //   {required: true, message: '请输入备注', trigger: 'change'},
        // ]
      },
      //商品分类list
      categorieslList:[],
      //选中的父级分类的ID数组
      selectefKeys: [],
      //指定级联选择器的配置对象
      cascaderPropscate: {
        expandTrigger: 'hover',
        // checkStrictly:true,
        value: 'c_id',
        label: 'c_name',
        children: 'children'
      },
      start_date:'',
      end_date:''
    }
  },
  created() {
    this.getsupplierList()
    this.getuserList()
    this.getcateList()
    // this.gettoday()
  },
  methods: {
    //获取今天时间
    // gettoday(){
    //   var myDate = new Date();
    //   this.addPurchaseForm.p_date=myDate.getFullYear() +'-'+ (myDate.getMonth()+1) +'-'+ myDate.getDate()
    // },
    //获取供应商列表
    async getsupplierList() {
      const {data: res} = await this.$http.get('supplier/getMainList')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.supplierList = res.data
    },
    //获取省市区
    async getWarehouseArea() {
      const {data: res} = await this.$http.get('listarea')
      if (res.code != 200) return this.$message.error('获取省市区数据失败')
      this.listarea = res.data
      console.log(this.listarea)
    },
    //获取商品数据
    async getGoodsList(id){
      const {data:res} = await this.$http.get('goods/getList',
          {params:id})
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.Goodslist=res.data.data
      this.total=res.data.total
      setTimeout(() => {
        this.pickUp() //需要默认勾选的数据
      }, 10)
    },
    //获取采购员
    async getuserList() {
      const {data: res} = await this.$http.get('member/getMainList')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.userlist=res.data
    },
    //监听pagesize改变
    handleSizeChange(newSize) {
      this.querInfo.limit = newSize
      this.getGoodsList(this.querInfo)
    },
    //监听pagenum 改变
    handleCurrentChange(newPage) {
      this.querInfo.page = newPage
      this.getGoodsList(this.querInfo)
    },
    // 需要默认勾选的数据
    pickUp() {
      let arr = []
      this.Goodslist.forEach((item) => {
        this.addPurchaseForm.goodslist.forEach((val) => {
          if (val.ga_id === item.ga_id) {
            arr.push(item)
            console.log(arr,'123456')
            // this.radios.push(item.roleID)
          }
        })
      })
      this.toggleSelection(arr)
    },
    // checkboxT(row, rowIndex){
    //   this.Goodslist.forEach((item) => {
    //     this.addPurchaseForm.goodslist.forEach((val) => {
    //       if(val.ga_id === item.ga_id){
    //         return false;//禁用
    //       }else{
    //         return true;//不禁用
    //       }
    //     })
    //   })
    //
    //
    // },
    // 默认选择中的行
    toggleSelection(rows) {
      console.log(rows)
      if (rows) {
        rows.forEach((row) => {
          this.$refs.docTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.docTable.clearSelection()
      }
    },
    //供应商选项框发生变化时的回调函数
    getcheckList(){
      this.addPurchaseForm.goodslist=[]
      if (this.addPurchaseForm.s_id == ''){
        this.adddisabled=true
        return true
      }else{
        this.querInfo.s_id=this.addPurchaseForm.s_id
        this.getGoodsList(this.querInfo)
        this.adddisabled=false
      }
    },
    add(){
      this.addGoodsDialogVisible=true
    },

    changeFun(selection) {
      let count = 0;
      this.Goodslist.forEach((items,indexs)=>{
        this.addPurchaseForm.goodslist.forEach((item,index)=>{
          if (items.ga_id === item.ga_id){
            this.addPurchaseForm.goodslist.splice(index, 1)
            count++;
          }
        })
      })
      if(count != this.querInfo.limit){
        this.Goodslist.forEach((item,index)=>{
          this.asd([],item)
        })
      }else{
        count = 0;
      }
    },
    // asd(selection, row){
    //   console.log(selection, row)
    //   let a=true
    //   if(this.addPurchaseForm.goodslist.length==0){
    //     // console.log(this.addPurchaseForm.goodslist)
    //     this.addPurchaseForm.goodslist.push(row);
    //   }else{
    //     this.addPurchaseForm.goodslist.forEach((item,index)=>{
    //       if (item.ga_id !== row.ga_id){
    //         // console.log(1)
    //       }else{
    //         a=false
    //         return
    //       }
    //     })
    //     if (a){
    //       this.addPurchaseForm.goodslist.push(row);
    //     }
    //   }
    // },
    asd(selection, row) {
      let is_exists = true;
      this.addPurchaseForm.goodslist.forEach((item,index)=>{
        if (this.addPurchaseForm.goodslist[index].ga_id===row.ga_id) {
          is_exists = false;
          this.addPurchaseForm.goodslist.splice(index, 1)
        } else {
          // is_exists = false;
        }
      })
      if (is_exists) {
        this.addPurchaseForm.goodslist.push(row)
        is_exists = true;
      }
    },
    delGoodsList(id){
      this.addPurchaseForm.goodslist.forEach((item,index)=>{
        if (item.ga_id===id){
          this.addPurchaseForm.goodslist.splice(index,1)
        }
      })
    },
    //提交form表单
    async addupPurchase(){
      this.$refs.addPurchaseFormRef.validate(async valid => {
        if (!valid) return
        this.addPloading=true
        const {data: res} = await this.$http.post('purchase/add', this.addPurchaseForm)
        this.addPloading=false
        if (res.code !== 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.$router.go(-1)
      })
    },
    addgo(){
      this.$router.go(-1)
    },
    getTotal() {
      this.addPurchaseForm.p_total_price=0
      this.addPurchaseForm.goodslist.forEach((item,index)=>{
        if (item.pi_total_price==0){
          if (item.pi_number<0){
            item.pi_number=0
          }else if(item.pi_price<0){
            console.log(item.pi_price)
            item.pi_price=0
          }
          item.pi_total_price=item.pi_number*1*item.pi_price*1
        }else{
          if (item.pi_number<0){
            item.pi_number=0
          }else if(item.pi_price<0){
            console.log(item.pi_price)

            item.pi_price=0
          }
          item.pi_total_price=item.pi_number*1*item.pi_price*1
          this.addPurchaseForm.p_total_price += item.pi_total_price*1
        }
      })
    },
    handleInput2(e) {
      // 通过正则过滤小数点后两位
      e.target.value = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null
    },
    addgoods(){
      console.log(this.addPurchaseForm.s_id)
      this.$router.push({
        path:"/goods/addgoods",
        query:{id:this.addPurchaseForm.s_id,typeindex:1}
      })
    },
    //添加供应商
    AddSupplierSelect() {
      this.addSupplierDialogVisible=true
      this.getWarehouseArea()
    },
    //监听添加对话框的显示与隐藏
    addSupplieDialoghandleClose(){
      this.$refs.addsupplierFormRef.resetFields()
      this.selectListarea = []
      this.addsupplierForm={}
    },
    //点击确定提交添加form表单
    addSupplier(){
      this.$refs.addsupplierFormRef.validate(async valid => {
        if (!valid) return
        this.addloading=true
        const {data: res} = await this.$http.post('supplier/add', this.addsupplierForm)
        this.addloading=false
        if (res.code !== 200) return this.$message.error(res.msg)
        this.$message.success("添加成功")
        this.addSupplierDialogVisible = false
        this.getsupplierList()
      })
    },
    //添加 监听级联选择器 选中节点变化时触发
    addSupplierChanged(){
      this.addsupplierForm.s_province=this.addsupplierForm.region[0]
      this.addsupplierForm.s_city=this.addsupplierForm.region[1]
      this.addsupplierForm.s_area=this.addsupplierForm.region[2]
    },
    //商品分类
    parentCateChanged() {
      this.querInfo.g_cid=this.selectefKeys[this.selectefKeys.length-1]
      console.log(this.querInfo.g_cid)
      this.getGoodsList(this.querInfo)
    },
    //获取商品分类
    async getcateList() {
      const {data: res} = await this.$http.get('category/getMainList/3')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.categorieslList = res.data
    },


  },
  computed: {
    // totalPrice() {
    //   let result = 0;
    //   for (let i = 0; i < this.addPurchaseForm.goodslist.length; i++) {
    //     this.addPurchaseForm.goodslist[i].pi_total_price =
    //         parseInt(this.addPurchaseForm.goodslist[i].pi_number) * parseInt(this.addPurchaseForm.goodslist[i].pi_price);
    //     result = parseInt(result) + parseInt(this.addPurchaseForm.goodslist[i].pi_total_price);
    //   }
    //
    //   return result
    // },
  },


}
</script>
<style lang="less" scoped>
.p_price,.p_purchase_id,.p_date,.p_remark,.p_other_price{
  width: 300px;
}
.p_date {
  width: 300px !important;
}
.el-select{
  width: 300px;
}
.addsupplierselect{
  margin-left: 30px;
}
/deep/.el-dialog{
  height: 75vh;
  overflow: auto;
}
/deep/::-webkit-scrollbar {
  width: 10px; /*对垂直流动条有效*/
}
/*定义滚动条的轨道颜色、内阴影及圆角*/
/deep/::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #ffffff;
  border-radius: 3px;
}
/*定义滑块颜色、内阴影及圆角*/
/deep/::-webkit-scrollbar-thumb{
  border-radius: 7px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #E8E8E8;
}

</style>
